import * as content from "../content/contentExport.js";

const lists = [
  {
    id: 1,
    songList: content.SongList,
  },
];

export default lists;
