import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faInstagram,
  faXTwitter,
  faFacebookF,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(faInstagram, faFacebookF, faYoutube, faXTwitter);

// faXTwitter
