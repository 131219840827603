import gallery1 from "../content/images/gallery/John-slaying-3.png";
import gallery2 from "../content/images/gallery/Larry-sing-3.png";
import gallery3 from "../content/images/gallery/Bob-focus2.png";
import gallery4 from "../content/images/gallery/shane-2.png";
import gallery5 from "../content/images/gallery/TD-Sing-2.png";
import gallery6 from "../content/images/gallery/TD_Singing_2.png";
import gallery7 from "../content/images/gallery/Shane-drums-2.png";
import gallery8 from "../content/images/gallery/Larry-sing-2.png";
import gallery9 from "../content/images/gallery/John-Gunslinger.png";
import gallery10 from "../content/images/gallery/Bob-new.png";
import gallery11 from "../content/images/gallery/john-slaying.png";
import gallery12 from "../content/images/gallery/Larry-singing.png";
import gallery13 from "../content/images/gallery/Larry-solo.png";
import gallery14 from "../content/images/gallery/Band-full.png";
import gallery15 from "../content/images/gallery/John-slaying-2.png";
import gallery16 from "../content/images/gallery/Shane.png";
import gallery17 from "../content/images/gallery/Bob-focus.png";
import gallery18 from "../content/images/gallery/Larry-singing-at-Maddies.png";
import gallery19 from "../content/images/gallery/Johnny.png";

const galleryImages = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
  gallery17,
  gallery18,
  gallery19,
];

export default galleryImages;
